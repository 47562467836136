import React, { lazy, Component, Suspense } from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import Badge from "antd/lib/badge"
import ConfigProvider from "antd/lib/config-provider"
import enUS from "antd/es/locale/en_US"
import arEG from "antd/es/locale/ar_EG"
import moment from "moment"
import "moment/locale/ar"
import merge from "lodash/merge"
import SendingIcon from "react-icons/lib/md/rotate-right"
import { clearAllBodyScrollLocks } from "body-scroll-lock"

import "./index.scss"

import { LangContext } from "./context"
import chatbotStyle from "../data/config/chatbotStyle"

import * as chatActions from "../data/redux/chat_details/actions"
import * as pageActions from "../data/redux/page_details/actions"

import {
  LOCAL_STORAGE,
  MESSAGE_TYPES,
  BUTTON_TYPES,
  BUTTON_SUB_TYPES,
  MESSAGE_SENDER,
  MESSAGE_READ_STATUS,
  EVENTS,
  CHATBOT_TYPE,
  PLATFORM,
  TYPES,
  LANGUAGES,
  // GOOGLE_ENABLER_EVENTS
} from "../data/config/constants"
import {
  log,
  checkDevice,
  isAndroid,
  isIOS,
  uniqueId,
  fetchWithTimeout,
  showMessage,
  clearAllDataFromLocalStorage,
  getDataFromLocalStorage,
  setDataInLocalStorage,
  isDeviceIOS,
  isAppleDevice,
  throttled,
  getPreviousMessageData,
  openUrlInNewTab,
} from "../data/config/utils"
import {
  chatbot_setting,
  translator,
  chatbot_default_messages,
  brand_features,
  chatbot_psids,
} from "../data/config/brandSetup"
import { networkCheckUrl, senderId } from "../data/config/urls"

const TriggerChatBot = lazy(() => import("../components/triggerchatbot"))
const ChatBot = lazy(() => import("./chatbot"))
const NotificationBot = lazy(() => import("./notificationbot"))

const defaultMessageLength =
  chatbot_default_messages.getDefaultMessages().length

const is_ios_device = isDeviceIOS()
const isApple = isAppleDevice()

moment.locale("en")

let GLOBAL_LAST_URL = window.location.href

class AppContainer extends Component {
  constructor(props) {
    super(props)
    window.androidObj = function AndroidClass() {}
    this.timeout = false
    this.chatbotRef = React.createRef()
    this.scrollTimerRef = React.createRef(null)
    this.userFirstEmit = false
    this.resetUnseenMessagesTimeout = null
    this.state = {
      allowedChatbot: brand_features.allowChatbotByBrandLogic(),
      render_chatbot: props.chat_details.is_chat_open,
      bot_popup_payload: null,
      selected_offer: {
        offer_id: null,
        offer_name: null,
      },
    }
  }

  componentDidMount() {
    const { chat_details, page_details, actions } = this.props
    const android = isAndroid()
    const ios = isIOS()
    let self = this
    if (page_details.language === LANGUAGES.ARABIC) moment.locale("ar")
    else if (page_details.language === LANGUAGES.ENGLISH) moment.locale("en")

    if (chatbot_setting.security.enable && !chat_details.secure) {
      const security_code = window.prompt(
        translator.text[page_details.language].security_prompt
      )
      if (security_code && security_code === chatbot_setting.security.code)
        actions.updateChatsState({ secure: true })
    }

    window.bot_popup = this.botPopup
    actions.updatePageState({
      device_data: checkDevice.deviceStatus(),
      direction: brand_features.getChatBotDirection(),
    })
    window.addEventListener("resize", () => {
      const node = document.getElementById("oriChatbotConversationContainer")
      if (node && window.innerWidth < 481) node.scrollTop = node.scrollHeight
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        const device_data = checkDevice.deviceStatus()
        if (
          self.props.page_details.device_data.screen_width !==
            device_data.screen_width ||
          self.props.page_details.device_data.screen_height !==
            device_data.screen_height
        )
          self.props.actions.updatePageState({
            device_data,
          })
      }, 300)
    })
    this.checkInternetConnection()
    window.addEventListener("online", this.checkInternetConnection)
    window.addEventListener("offline", this.checkInternetConnection)
    window.addEventListener("click", this.handleClickEventListener)
    document.addEventListener("visibilitychange", this.onScreenVisibilityChange)
    document.addEventListener("focusin", this.onScreenVisibilityChange)
    if (android || ios || true) {
      // window.androidObj.updateFromAndroid = (type, data) => {
      //   const update_type = type.toLowerCase()
      //   if (update_type === PLATFORM.ANDROID) {
      //     localStorage.removeItem(PLATFORM.IOS)
      //   } else if (update_type === PLATFORM.IOS) {
      //     localStorage.removeItem(PLATFORM.ANDROID)
      //   }
      //   if (update_type === PLATFORM.ANDROID || update_type === PLATFORM.IOS) {
      //     localStorage.setItem(update_type, JSON.stringify(true))
      //   } else if (update_type === "psid") {
      //     /*
      //     Changed By: Nazia
      //     Reason: Get object from java in double quotes instead of single quote so we can't stringify it.
      //     Old Code : At line no: 133 (data = JSON.parse(data))
      //     */
      //     data = JSON.parse(JSON.parse(JSON.stringify(data)))
      //     if (data.psid) {
      //       clearAllDataFromLocalStorage(chat_details.psid)
      //       actions.updateChatsState({ psid: data.psid })
      //       chatbot_psids.setPsid(data.psid)
      //     }
      //     if (data.params) {
      //       let psid = data.psid ? data.psid : chat_details.psid
      //       setDataInLocalStorage(LOCAL_STORAGE.APP_PARAMS + psid, data.params)
      //     }
      //     if (!chat_details.is_socket_connected) {
      //       actions.updateChatsState({ messages: [] })
      //       actions.makeSocketConnection()
      //     }
      //   } else if (
      //     update_type === "endchat" &&
      //     this.chatbotRef &&
      //     this.chatbotRef.current &&
      //     this.chatbotRef.current.onClickCloseIcon
      //   ) {
      //     this.chatbotRef.current.onClickCloseIcon()
      //   }
      // }
      window.updateFromAndroid = (type, data) => {
        const update_type = type.toLowerCase()
        if (update_type === PLATFORM.ANDROID) {
          localStorage.removeItem(PLATFORM.IOS)
        } else if (update_type === PLATFORM.IOS) {
          localStorage.removeItem(PLATFORM.ANDROID)
        }
        if (update_type === PLATFORM.ANDROID || update_type === PLATFORM.IOS) {
          localStorage.setItem(update_type, JSON.stringify(true))
        } else if (update_type === "psid") {
          /*
          Changed By: Nazia
          Reason: Get object from java in double quotes instead of single quote so we can't stringify it.
          Old Code : At line no: 133 (data = JSON.parse(data))
          */
          data = JSON.parse(JSON.parse(JSON.stringify(data)))
          if (data.psid) {
            clearAllDataFromLocalStorage(chat_details.psid)
            actions.updateChatsState({ psid: data.psid })
            chatbot_psids.setPsid(data.psid)
          }
          if (data.params) {
            let psid = data.psid ? data.psid : chat_details.psid
            setDataInLocalStorage(LOCAL_STORAGE.APP_PARAMS + psid, data.params)
          }
          if (!chat_details.is_socket_connected) {
            actions.updateChatsState({ messages: [] })
            actions.makeSocketConnection()
          }
        } else if (
          update_type === "endchat" &&
          this.chatbotRef &&
          this.chatbotRef.current &&
          this.chatbotRef.current.onClickCloseIcon
        ) {
          this.chatbotRef.current.onClickCloseIcon()
        }
      }
      actions.handleChatbotInterface(true)
    } else if (!chat_details.is_socket_connected) {
      if (chatbot_setting.chatbot_type === CHATBOT_TYPE.FULL_SCREEN) {
        document.title = translator.text[page_details.language].brandName
        actions.makeSocketConnection()
      } else if (chatbot_setting.chatbot_type === CHATBOT_TYPE.DEFAULT) {
        const query_params = new URLSearchParams(window.location.search)
        if (
          chatbot_setting.auto_open_chatbot.enable &&
          query_params.has(chatbot_setting.auto_open_chatbot.query_param_key)
        ) {
          const query_param_value = query_params.get(
            chatbot_setting.auto_open_chatbot.query_param_key
          )
          if (query_param_value === "true") this.handleSocketConnection(true)
          else if (query_param_value === "false")
            actions.handleChatbotInterface(false)
        }
        let last_emit = getDataFromLocalStorage(
          LOCAL_STORAGE.LAST_EMIT + chat_details.psid,
          null
        )
        if (last_emit) {
          let current_time = new Date().getTime()
          let time_gap = (current_time - last_emit) / 1000
          if (
            chat_details.is_chat_open &&
            time_gap < chatbot_setting.automate_connection_time
          )
            actions.makeSocketConnection()
          else if (chat_details.is_chat_open)
            actions.handleChatbotInterface(false)
          if (time_gap > chatbot_setting.automate_reset_chat_time) {
            actions.updateChatsState({
              messages: [],
              disable_msg_after_reply: {},
              messageLoading: [],
            })
            localStorage.removeItem(
              LOCAL_STORAGE.DISABLE_MESSAGE_AFTER_USER_REPLY + chat_details.psid
            )
            localStorage.removeItem(LOCAL_STORAGE.MESSAGES + chat_details.psid)
          }
        }
      }
    }
    if (brand_features.enable_onload_brand_logic)
      brand_features.doBrandLogicOnLoadChatbotApp()

    if (chatbot_setting.auto_hide_notification_bubbles.enable)
      this.hideNotificationBubbles()
    if (!isApple) {
      const element = document.getElementById("oriAppContainer")
      element.classList.add("scroll-visibility")
      window.addEventListener("scroll", this.handleScrollbarView, true)
    }
  }

  componentDidUpdate(prevProps) {
    const { actions, page_details } = this.props
    // const { bot_popup_payload } = this.state
    const {
      is_socket_connected,
      is_internet_connected,
      is_chat_open,
      unseen_messages,
    } = this.props.chat_details

    //---------------- TO FIX IOS SCROLL ISSUE -----------------
    if (window.innerWidth < 768) {
      if (is_chat_open) document.body.style.overflow = "hidden"
      else document.body.style.overflow = "auto"
    }
    //----------------------------------------------------------
    if (prevProps.page_details.language !== page_details.language) {
      if (page_details.language === LANGUAGES.ARABIC) moment.locale("ar")
      else if (page_details.language === LANGUAGES.ENGLISH) moment.locale("en")
    }

    if (prevProps.chat_details.is_chat_open && !is_chat_open) {
      setTimeout(() => this.setState({ render_chatbot: false }), 400)
    } else if (!prevProps.chat_details.is_chat_open && is_chat_open) {
      this.setState({ render_chatbot: true })
      if (this.resetUnseenMessagesTimeout)
        clearTimeout(this.resetUnseenMessagesTimeout)
    }
    if (
      chatbot_setting.auto_hide_notification_bubbles.enable &&
      !is_chat_open &&
      unseen_messages.length > prevProps.chat_details.unseen_messages.length
    )
      this.hideNotificationBubbles()

    // if (!prevProps.chat_details.is_socket_connected && is_socket_connected && bot_popup_payload) {
    //   actions.handleBotPopupRequest(bot_popup_payload)
    //   this.setState({ bot_popup_payload: null })
    // }
    if (
      !prevProps.chat_details.is_internet_connected &&
      is_internet_connected &&
      !is_socket_connected
    )
      actions.callSocketMethod("open")
    else if (
      prevProps.chat_details.is_internet_connected &&
      !is_internet_connected &&
      is_socket_connected
    )
      actions.callSocketMethod("close")
    if (prevProps.chat_details.is_socket_connected !== is_socket_connected)
      this.checkInternetConnection()
  }

  componentWillUnmount() {
    const { actions } = this.props
    window.removeEventListener("click", this.handleClickEventListener)
    window.removeEventListener("online", this.checkInternetConnection)
    window.removeEventListener("offline", this.checkInternetConnection)
    document.removeEventListener(
      "visibilitychange",
      this.onScreenVisibilityChange
    )
    document.removeEventListener("focusin", this.onScreenVisibilityChange)
    if (this.resetUnseenMessagesTimeout)
      clearTimeout(this.resetUnseenMessagesTimeout)
    actions.socketDisconnect()
    if (is_ios_device) clearAllBodyScrollLocks()
    if (!isApple) {
      window.removeEventListener("scroll", this.handleScrollbarView)
      if (this.scrollTimerRef.current) clearTimeout(this.scrollTimerRef.current)
    }
  }

  handleClickEventListener = () => {
    requestAnimationFrame(() => {
      if (GLOBAL_LAST_URL !== window.location.href) {
        GLOBAL_LAST_URL = window.location.href
        console.log("url changed", GLOBAL_LAST_URL)
        const allowed = brand_features.allowChatbotByBrandLogic()
        if (allowed !== this.state.allowedChatbot)
          this.setState({ allowedChatbot: allowed })
      }
    })
  }

  hideNotificationBubbles = () => {
    const { actions, chat_details } = this.props
    if (this.resetUnseenMessagesTimeout)
      clearTimeout(this.resetUnseenMessagesTimeout)
    if (
      chat_details.unseen_messages &&
      chat_details.unseen_messages.length > 0
    ) {
      this.resetUnseenMessagesTimeout = setTimeout(() => {
        const payload = {
          unseen_messages: [],
          notification_count: 0,
        }
        if (
          chat_details.unseen_messages.length >= chat_details.messages.length
        ) {
          payload.messages = []
          localStorage.removeItem(LOCAL_STORAGE.MESSAGES + chat_details.psid)
        }
        localStorage.removeItem(
          LOCAL_STORAGE.UNSEEN_MESSAGES + chat_details.psid
        )
        localStorage.removeItem(
          LOCAL_STORAGE.NOTIFICATION_COUNT + chat_details.psid
        )
        actions.updateChatsState(payload)
      }, chatbot_setting.auto_hide_notification_bubbles.delay)
    }
  }

  onScreenVisibilityChange = () => {
    const { chat_details, actions } = this.props
    log("onScreenVisibilityChange called")
    if (document.visibilityState === "visible" && chat_details.is_chat_open) {
      const payload = {
        clientPsid: chat_details.psid,
        senderPsid: chat_details.psid,
      }
      actions.emitCustomEvent(EVENTS.MESSAGE_SEEN, payload)
    }
  }

  checkInternetConnection = () => {
    const { actions, chat_details } = this.props
    if (navigator.onLine) {
      if (!chat_details.internet_connection_checking) {
        actions.updateChatsState({ internet_connection_checking: true })
        fetchWithTimeout(networkCheckUrl, {
          mode: "no-cors",
        })
          .then(() => {
            actions.updateChatsState({
              internet_connection_checking: false,
              is_internet_connected: true,
            })
          })
          .catch((error) => {
            actions.updateChatsState({ internet_connection_checking: false })
            if (error && error.message === "timeout") {
              actions.updateChatsState({ is_internet_connected: false })
              showMessage("error", "You are currently offline")
            }
          })
      }
    } else {
      actions.updateChatsState({ is_internet_connected: false })
      showMessage("error", "You are currently offline")
    }
  }

  botPopup = (case_data, params) => {
    log("botPopup called", case_data, params)
    const { chat_details, actions } = this.props
    let payload = {
      case: case_data,
      params: params,
      psid: chat_details.psid,
    }
    // if (chat_details.is_socket_connected)
    actions.handleBotPopupRequest(payload)
    // else {
    //   this.setState({ bot_popup_payload: payload })
    //   actions.makeSocketConnection()
    // }
  }

  handleSocketConnection = (bool) => {
    brand_features.doSomethingBeforeLoadChatbotThenContinue(() => {
      const { chat_details, actions } = this.props
      actions.handleChatbotInterface(bool)
      if (bool) {
        const android = isAndroid()
        const ios = isIOS()
        if (chatbot_setting.emit_unread_msg_seen)
          actions.emitCustomEvent(EVENTS.UNREAD_MESSAGE_SEEN, {
            clientPsid: chat_details.psid,
          })
        else if (!chat_details.is_socket_connected && !android && !ios)
          actions.makeSocketConnection()
        // const payload = {
        //   clientPsid: chat_details.psid,
        //   senderPsid: chat_details.psid,
        // }
        // actions.emitCustomEvent(EVENTS.MESSAGE_SEEN, payload)
      }
    })
  }

  handleOfferSelection = (offer_id, offer_name) => {
    this.setState({
      selected_offer: {
        ...this.state.selected_offer,
        offer_id,
        offer_name,
      },
    })
  }

  setDefaultOfferState = () => {
    this.setState({
      selected_offer: {
        ...this.state.selected_offer,
        offer_id: null,
        offer_name: null,
      },
    })
  }

  handleDataSubmit = (data, updatedMessage) => {
    const { chat_details } = this.props
    if (data.list && data.list.length > 0) {
      const post_back_message_key = getPreviousMessageData(
        chat_details.messages,
        "post_back_message_key",
        undefined
      )
      const cmid = uniqueId()
      const response = {
        type: MESSAGE_TYPES.LIST,
        ...data,
        cmid,
      }
      const obj = {
        payload: { list: data.list, showLabelOnly: data.showLabelOnly },
        cmid,
      }
      if (!post_back_message_key?.hideUserResponse)
        this.pushSenderNewMsgToChatbot(MESSAGE_TYPES.LIST, obj)
      this.emitResponseToServer(response)
    } else if (data.text) {
      const cmid = uniqueId()
      const response = {
        type: MESSAGE_TYPES.TEXT,
        ...data,
        cmid,
      }
      const obj = {
        containsHTML: !!data.containsHTML,
        payload: { text: data.text },
        cmid,
      }
      this.pushSenderNewMsgToChatbot(MESSAGE_TYPES.TEXT, obj)
      this.emitResponseToServer(response)
    }

    if (updatedMessage) {
      console.log("updatedMessage", updatedMessage, data)
    }
  }

  onEndChatSuccess = () => {
    this.userFirstEmit = false
  }

  emitResponseToServer = (response) => {
    const { chat_details, actions, page_details } = this.props
    const post_back_message_key = getPreviousMessageData(
      chat_details.messages,
      "post_back_message_key",
      undefined
    )
    const android = isAndroid()
    const ios = isIOS()
    const data = {
      ...response,
      post_back_message_key,
      sender_id: senderId,
      navigator_userAgent: navigator.userAgent,
      navigator_platform: navigator.platform,
      variable_name: chat_details.variable_name,
      send_variable_to_apiai: chat_details.send_variable_to_apiai,
      sendVariableToLS: chat_details.sendVariableToLS,
      skipLS: chat_details.skipLS,
    }

    if (!this.userFirstEmit) brand_features.doBrandLogicOnUserFirstEmit()

    if (
      !this.userFirstEmit &&
      chat_details.messages.length <= defaultMessageLength &&
      chatbot_setting.send_brand_data_on_user_first_msg
    ) {
      this.userFirstEmit = true
      data.brandData = brand_features.getBrandData()
      brand_features.doBrandLogicOnUserFirstEmit()
      // if (chatbot_setting.chatbot_type === CHATBOT_TYPE.ADSTER && window.parent)
      //   window.parent.postMessage({
      //     type: "exit",
      //     func: GOOGLE_ENABLER_EVENTS.CHAT_SESSION_START,
      //     message: window.location.hostname
      //   }, '*')
    }

    if (android || ios || true) {
      const lockedParams = getDataFromLocalStorage(
        LOCAL_STORAGE.APP_PARAMS + chat_details.psid,
        null
      )
      if (lockedParams) {
        data.lockedParams = lockedParams
        localStorage.removeItem(LOCAL_STORAGE.APP_PARAMS + chat_details.psid)
      }
    }
    if (chatbot_setting.chatbot_type === CHATBOT_TYPE.ADSTER) {
      data.adSegment = page_details.banner_key
      data.bannerWidth = page_details.banner_width
      data.bannerHeight = page_details.banner_height
      data.screenHeight = page_details.device_data.screen_height
      data.screenWidth = page_details.device_data.screen_width
    }
    actions.emitNewMessageToServer(data)
    setDataInLocalStorage(
      LOCAL_STORAGE.LAST_EMIT + chat_details.psid,
      new Date().getTime()
    )
  }

  pushSenderNewMsgToChatbot = (type, data) => {
    const { actions } = this.props
    const user_message = {
      type,
      sender_id: senderId,
      timestamp: new Date(),
      sender: MESSAGE_SENDER.CUSTOMER,
      readStatus: MESSAGE_READ_STATUS.SENDING,
      ...data,
    }
    actions.pushSenderMessage(user_message)
  }

  sendTextToServer = (text, autoSuggestion, replyInfo) => {
    const cmid = uniqueId()
    const response = {
      type: MESSAGE_TYPES.TEXT,
      text,
      cmid,
      ...autoSuggestion,
      ...replyInfo,
    }
    const data = {
      payload: { text },
      cmid,
      ...autoSuggestion,
      ...replyInfo,
    }
    this.emitResponseToServer(response)
    this.pushSenderNewMsgToChatbot(MESSAGE_TYPES.TEXT, data)
  }

  handleAutoEmit = () => {
    const { chat_details, actions } = this.props
    if (chatbot_setting.auto_emit_message.enable) {
      if (chatbot_setting.auto_emit_message.get_default_messages) {
        actions.emitCustomEvent(
          EVENTS.GET_DEFAULT_MESSAGES,
          brand_features.getBrandData()
        )
      } else {
        let text = brand_features.getAutoEmitMessage()
        if (text) {
          const data = {
            type: MESSAGE_TYPES.TEXT,
            text,
            cmid: uniqueId(),
            sender_id: senderId,
            navigator_userAgent: navigator.userAgent,
            navigator_platform: navigator.platform,
            variable_name: chat_details.variable_name,
            send_variable_to_apiai: chat_details.send_variable_to_apiai,
            sendVariableToLS: chat_details.sendVariableToLS,
            skipLS: chat_details.skipLS,
          }
          if (chatbot_setting.auto_emit_message.send_brand_data)
            data.brandData = brand_features.getBrandData()
          actions.emitNewMessageToServer(data)
          if (chatbot_setting.auto_emit_message.emit_once)
            chatbot_setting.auto_emit_message.enable = false
          if (chatbot_setting.auto_emit_message.update_last_emit)
            setDataInLocalStorage(
              LOCAL_STORAGE.LAST_EMIT + chat_details.psid,
              new Date().getTime()
            )
        }
      }
    }
  }

  handleButtonSubTypes = (data) => {
    switch (data.button.subtype) {
      // case BUTTON_SUB_TYPES.DISH_OFFERS:
      //   const { selected_offer } = this.state
      //   const cmid = uniqueId()
      //   const response = {
      //     type: MESSAGE_TYPES.TEXT,
      //     text: selected_offer.offer_id,
      //     cmid,
      //   }
      //   const payload_data = {
      //     payload: { text: selected_offer.offer_name },
      //     cmid,
      //   }
      //   this.pushSenderNewMsgToChatbot(MESSAGE_TYPES.TEXT, payload_data)
      //   this.emitResponseToServer(response)
      //   if (selected_offer.offer_id) {
      //     this.setDefaultOfferState()
      //   }
      //   break

      // case BUTTON_SUB_TYPES.CHECKBOX_SUBMIT:
      //   if (data.message && data.message.payload && data.message.payload.options) {
      //     const { selected_checkbox_values } = this.state
      //     const selected_checkbox_items = data.message.payload.options.filter((item) => {
      //       return selected_checkbox_values.findIndex(value => value === item.value) !== -1
      //     })
      //     if (selected_checkbox_items.length > 0) {
      //       const cmid = uniqueId()
      //       const response = {
      //         type: MESSAGE_TYPES.LIST,
      //         list: selected_checkbox_items,
      //         relayData: data.button.relayData,
      //         cmid
      //       }
      //       const obj = {
      //         payload: { list: selected_checkbox_items },
      //         cmid,
      //       }
      //       this.pushSenderNewMsgToChatbot(MESSAGE_TYPES.LIST, obj)
      //       this.emitResponseToServer(response)
      //     }
      //   }
      //   break

      case BUTTON_SUB_TYPES.SHARE_LOCATION:
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            const lat = position.coords.latitude
            const long = position.coords.longitude
            const cmid = uniqueId()
            const response = {
              type: MESSAGE_TYPES.LOCATION,
              lat: lat.toString(),
              long: long.toString(),
              cmid,
            }
            const payload_data = {
              payload: { text: "You shared your location." },
              cmid,
            }
            this.pushSenderNewMsgToChatbot(MESSAGE_TYPES.TEXT, payload_data)
            this.emitResponseToServer(response)
          })
        }
        break

      default:
        return
    }
  }

  handleMsgBtnClick = (data) => {
    const { page_details, chat_details, actions } = this.props
    if (data.button) {
      brand_features.doBrandLogicOnClickOut()
      if (chatbot_setting.gtm.enable && data.button.tracker && window.dataLayer)
        window.dataLayer.push({ event: data.button.tracker })

      if (
        chatbot_setting.chatbot_type === CHATBOT_TYPE.ADSTER &&
        window.parent &&
        data.button.type !== BUTTON_TYPES.LINK
      )
        window.parent.postMessage(
          {
            type: "counter",
            func: data.button.adEvent ? data.button.adEvent : data.button.text,
            message: "",
          },
          "*"
        )

      if (
        data.button.disableAfterClick &&
        data.message &&
        data.message.payload
      ) {
        if (Array.isArray(data.message.payload)) {
          data.message.payload.forEach((el) => {
            if (el.buttons && el.buttons.length > 0)
              el.buttons.forEach((btn) => {
                if (
                  btn.type === data.button.type &&
                  btn.text === data.button.text
                )
                  btn.disabled = true
              })
          })
        } else {
          data.message.payload.buttons.forEach((btn) => {
            if (btn.type === data.button.type && btn.text === data.button.text)
              btn.disabled = true
          })
        }

        const payload = {
          cmid: data.message.cmid,
          changedValue: {
            payload: data.message.payload,
          },
        }
        actions.updateMessage(payload, "cmid")
      }

      switch (data.button.type) {
        case BUTTON_TYPES.LINK:
          if (data.button.url) {
            if (window.ReactNativeWebView) {
              window.ReactNativeWebView.postMessage(JSON.stringify({ "type" : "buttonClicked","data" : data})
              )
            }
            if (
              isAndroid() &&
              window.androidObj &&
              window.androidObj.textToAndroid
            ) {
              data.data = {} //TODO: voda android app specific (for handling deep link error)
              window.androidObj.textToAndroid(JSON.stringify(data))
            } else if (isIOS()) {
              if (
                window.webkit &&
                window.webkit.messageHandlers &&
                window.webkit.messageHandlers.updateFromWeb
              )
                window.webkit.messageHandlers.updateFromWeb.postMessage({
                  type: "buttonClicked",
                  data: data.button,
                })
              // else {
              //   // eslint-disable-next-line no-eval
              //   eval("if(updateFromWeb) updateFromWeb('buttonClicked', data)")
              // }
            } else {
              if (data.button.target === "newwindow") {
                window.open(
                  data.button.url,
                  "newwindow",
                  `width=${chatbot_setting.new_window_positon_and_size.width},height=${chatbot_setting.new_window_positon_and_size.height},top=${chatbot_setting.new_window_positon_and_size.top},left=${chatbot_setting.new_window_positon_and_size.left} resizable=yes, location=yes, scrollbars=yes`
                )
              } else {
                window.open(data.button.url, "_blank")
              }
            }
            if (
              chatbot_setting.chatbot_type === CHATBOT_TYPE.ADSTER &&
              window.parent
            )
              window.parent.postMessage(
                {
                  type: "exit",
                  func: data.button.adEvent
                    ? data.button.adEvent
                    : data.button.text,
                  message: data.button.url,
                },
                "*"
              )
          }
          break

        case BUTTON_TYPES.CUSTOM:
          if (data.button.subtype) {
            this.handleButtonSubTypes(data)
          }
          break

        case BUTTON_TYPES.CUSTOM_SOCKET_EVENT:
          {
            const cmid = uniqueId()
            if (data.button.text) {
              const obj = {
                payload: { text: data.button.text },
                cmid,
              }
              this.pushSenderNewMsgToChatbot(MESSAGE_TYPES.TEXT, obj)
            }

            if (data.button.eventName) {
              const post_back_message_key = getPreviousMessageData(
                chat_details.messages,
                "post_back_message_key",
                undefined
              )
              let payload = {
                relayData: merge(
                  {},
                  data.message.relayData,
                  data.button.relayData
                ),
                text: data.button.text,
                type:
                  data?.message?.payload[0] &&
                  data.message.payload[0].expectedClientResponseType
                    ? data.message.payload[0].expectedClientResponseType
                    : data?.message?.payload?.expectedClientResponseType
                    ? data.message.payload.expectedClientResponseType
                    : MESSAGE_TYPES.TEXT,
                cmid,
                ...(chatbot_setting.chatbot_type === CHATBOT_TYPE.ADSTER && {
                  adSegment: page_details.banner_key,
                  bannerWidth: page_details.banner_width,
                  bannerHeight: page_details.banner_height,
                  screenHeight: page_details.device_data.screen_height,
                  screenWidth: page_details.device_data.screen_width,
                }),
                ...(chatbot_setting.send_brand_data_on_user_first_msg && {
                  brandData: brand_features.getBrandData(),
                }),
                post_back_message_key,
              }

              if (!this.userFirstEmit) {
                this.userFirstEmit = true
                brand_features.doBrandLogicOnUserFirstEmit()
              }

              if (isAndroid() || isIOS()) {
                const lockedParams = getDataFromLocalStorage(
                  LOCAL_STORAGE.APP_PARAMS + chat_details.psid,
                  null
                )
                if (lockedParams) payload.lockedParams = lockedParams
                localStorage.removeItem(
                  LOCAL_STORAGE.APP_PARAMS + chat_details.psid
                )
              }

              this.props.actions.emitCustomEvent(
                data.button.eventName,
                payload,
                (err, res) => {
                  if (err) {
                    const payload = {
                      cmid,
                      changedValue: { readStatus: MESSAGE_READ_STATUS.FAILED },
                    }
                    actions.updateMessage(payload, "cmid")
                  } else if (
                    !err &&
                    res &&
                    res.data &&
                    res.data.cmid &&
                    res.data.changedValue
                  ) {
                    actions.updateMessage(res.data, "cmid")
                  }
                }
              )
            }
          }
          break

        case BUTTON_TYPES.POST_BACK_RESPONSE:
          {
            const cmid = uniqueId()
            if (data.button.text) {
              const obj = {
                payload: { text: data.button.text },
                cmid,
              }
              this.pushSenderNewMsgToChatbot(MESSAGE_TYPES.TEXT, obj)
            }
            if (data.button.postbackRes) {
              const response = {
                type: MESSAGE_TYPES.TEXT,
                text: data.button.postbackRes,
                cmid,
              }
              this.emitResponseToServer(response)
            }
          }
          break

        default:
          if (data.button.text) {
            this.sendTextToServer(data.button.text)
          }
      }
    }
  }

  handleFileUpload = (data, message) => {
    console.log("handleFileUpload", data, message)
    if (data && data?.fileUrl && data?.file && message?.chatlogId) {
      const { chat_details, actions } = this.props
      const post_back_message_key = getPreviousMessageData(
        chat_details.messages,
        "post_back_message_key",
        undefined
      )
      const cmid = uniqueId()
      actions.updateChatsState({
        messageLoading: {
          ...chat_details.messageLoading,
          [message.chatlogId]: true,
        },
      })
      actions.emitCustomEvent(
        EVENTS.MESSAGE_FILE_UPLOAD,
        {
          chatlogId: message.chatlogId,
          relayData: message?.payload[0]
            ? message.payload[0].relayData
            : message?.payload
            ? message.payload.relayData
            : null,
          fileBase64: data.fileUrl,
          fileName: data.file.name,
          cmid,
          post_back_message_key,
        },
        (err, res) => {
          actions.updateChatsState({
            messageLoading: {
              ...chat_details.messageLoading,
              [message.chatlogId]: false,
            },
          })
          if (
            !err &&
            res?.data?.chatlogId &&
            res?.data?.fileName &&
            res?.data?.fileUrl
          ) {
            actions.updateMessagePayload({
              key: "chatlogId",
              chatlogId: res.data.chatlogId,
              changedValue: {
                fileUrl: res.data.fileUrl,
                file: {
                  name: res.data.fileName,
                },
              },
            })
            this.pushSenderNewMsgToChatbot(MESSAGE_TYPES.IMAGE_WITH_BUTTONS, {
              payload: { title: res.data.fileName, imageUrl: res.data.fileUrl },
              readStatus: MESSAGE_READ_STATUS.SENT,
              cmid,
            })
          }
        }
      )
    }
  }

  downloadFile = (data) => {
    openUrlInNewTab(data.fileUrl, "_self")
  }

  handleDocxFileUpload = (data, retry) => {
    const { actions } = this.props
    let payload = {}
    const cmid = data?.cmid ? data.cmid : uniqueId()
    if (retry) {
      if (data && data.cmid && data.payload) {
        actions.updateMessage(
          {
            cmid: cmid,
            changedValue: {
              status: "pending",
              readStatus: MESSAGE_READ_STATUS.SENDING,
            },
          },
          "cmid"
        )
      }
      payload = {
        cmid: cmid,
        fileBase64: data.payload.fileUrl,
        ...data.payload,
      }
      delete payload.fileUrl
    } else {
      payload = {
        cmid: cmid,
        fileName: data.file.name,
        fileSize: data.file.size,
        fileType: data.file.type,
        fileBase64: data.fileUrl,
        pages: data?.pages || 0,
      }
      this.pushSenderNewMsgToChatbot(MESSAGE_TYPES.UPLOADED_DOCUMENT, {
        payload: {
          fileName: data.file.name,
          fileSize: data.file.size,
          fileType: data.file.type,
          fileUrl: data.fileUrl,
          pages: data?.pages || 0,
        },
        cmid,
        status: "pending",
      })
    }
    actions.emitCustomEvent(
      EVENTS.NEW_MESSAGE,
      {
        type: MESSAGE_TYPES.UPLOADED_DOCUMENT,
        ...payload,
      },
      (err, res) => {
        if (err) {
          const payload = {
            cmid: cmid,
            changedValue: {
              readStatus: MESSAGE_READ_STATUS.FAILED,
              status: "failed",
            },
          }
          actions.updateMessage(payload, "cmid")
        } else if (
          !err &&
          res?.data?.cmid &&
          res?.data?.fileName &&
          res?.data?.fileUrl
        ) {
          actions.updateMessagePayload(
            {
              key: "cmid",
              cmid: res?.data?.cmid,
              changedValue: {
                fileUrl: res?.data?.fileUrl,
              },
              changedMessageKey: {
                readStatus: MESSAGE_READ_STATUS.SENT,
                status: "success",
              },
            },
            "cmid"
          )
        }
      }
    )
  }

  handleScrollbarView = throttled((event) => {
    const element = document.getElementById("oriChatbotConversationContainer")
    if (element && element.contains(event.target)) {
      event.target.style.setProperty("--scroll-display", "visible")
      event.target.classList.add("prevent-inheritance")
      if (this.scrollTimerRef.current) {
        clearTimeout(this.scrollTimerRef.current)
      }
      this.scrollTimerRef.current = setTimeout(() => {
        event.target.style.setProperty("--scroll-display", "none")
      }, 800)
    }
  }, 800)

  render() {
    const { page_details, chat_details, actions } = this.props
    if (chatbot_setting.security.enable && !chat_details.secure) return null
    if (chatbot_setting.chatbot_type === CHATBOT_TYPE.FULL_SCREEN)
      return (
        <ConfigProvider
          locale={page_details.language === LANGUAGES.ARABIC ? arEG : enUS}
        >
          <LangContext.Provider value={page_details.language}>
            <div
              id="oriAppContainer"
              className={`ori-app-container ori-ant-design-container oriFullScreenAppContainer oriAppContainer ${
                page_details.direction === "rtl" ? "oriAppContainerRTL" : ""
              }`}
              style={{
                backgroundImage: `url(${
                  translator.assets[page_details.language].outerBackground
                })`,
                direction: page_details.direction,
              }}
            >
              <Suspense fallback={null}>
                <div
                  className={`ori-animate ori-fade-in ori-overflow-hidden ${
                    page_details.device_data.screen_width < 768 ||
                    page_details.device_data.screen_height < 400
                      ? chatbotStyle.containerMobileClass
                      : chatbotStyle.containerWebClass
                  }`}
                >
                  <ChatBot
                    ref={this.chatbotRef}
                    screen_height={page_details.device_data.screen_height}
                    chat_details={chat_details}
                    actions={actions}
                    sendTextToServer={this.sendTextToServer}
                    handleMsgBtnClick={this.handleMsgBtnClick}
                    handleFileUpload={this.handleFileUpload}
                    handleOfferSelection={this.handleOfferSelection}
                    handleDataSubmit={this.handleDataSubmit}
                    handleAutoEmit={this.handleAutoEmit}
                    onEndChatSuccess={this.onEndChatSuccess}
                    handleDocxFileUpload={this.handleDocxFileUpload}
                    downloadFile={this.downloadFile}
                  />
                </div>
              </Suspense>
            </div>
          </LangContext.Provider>
        </ConfigProvider>
      )
    else if (chatbot_setting.chatbot_type === CHATBOT_TYPE.ADSTER)
      return (
        <ConfigProvider
          locale={page_details.language === LANGUAGES.ARABIC ? arEG : enUS}
        >
          <LangContext.Provider value={page_details.language}>
            <Suspense fallback={null}>
              <div
                id="oriAppContainer"
                className={`ori-app-container ori-ant-design-container oriAppContainer ${
                  page_details.direction === "rtl" ? "oriAppContainerRTL" : ""
                }`}
                style={{
                  height: "100%",
                  width: "100%",
                  direction: page_details.direction,
                }}
              >
                <ChatBot
                  ref={this.chatbotRef}
                  screen_height={page_details.device_data.screen_height}
                  chat_details={chat_details}
                  actions={actions}
                  sendTextToServer={this.sendTextToServer}
                  handleMsgBtnClick={this.handleMsgBtnClick}
                  handleFileUpload={this.handleFileUpload}
                  handleOfferSelection={this.handleOfferSelection}
                  handleDataSubmit={this.handleDataSubmit}
                  banner_url={page_details.banner_url}
                  handleAutoEmit={this.handleAutoEmit}
                  onEndChatSuccess={this.onEndChatSuccess}
                  handleDocxFileUpload={this.handleDocxFileUpload}
                  downloadFile={this.downloadFile}
                />
              </div>
            </Suspense>
          </LangContext.Provider>
        </ConfigProvider>
      )
    else if (this.state.allowedChatbot)
      return (
        <ConfigProvider
          locale={page_details.language === LANGUAGES.ARABIC ? arEG : enUS}
        >
          <LangContext.Provider value={page_details.language}>
            <div
              id="oriAppContainer"
              className={`ori-app-container ori-ant-design-container oriAppContainer ${
                page_details.direction === "rtl" ? "oriAppContainerRTL" : ""
              }`}
              style={{
                direction: page_details.direction,
              }}
            >
              {(page_details.device_data.screen_width > 480 ||
                (page_details.device_data.screen_width < 481 &&
                  !chat_details.is_chat_open)) && (
                <Suspense fallback={null}>
                  <Badge
                    count={
                      chatbot_setting.trigger_type !== TYPES.FLAOTING_MENU
                        ? chat_details.notification_count
                        : 0
                    }
                    overflowCount={9}
                    className="ori-animated ori-fade-in notificationBadge"
                  >
                    <TriggerChatBot
                      mobile={page_details.device_data.screen_width < 481}
                      is_chat_open={chat_details.is_chat_open}
                      handleSocketConnection={this.handleSocketConnection}
                    />
                  </Badge>
                </Suspense>
              )}
              {this.state.render_chatbot && (
                <Suspense
                  fallback={
                    <SendingIcon className="ori-l-mrgn-5 ori-animated ori-rotate ori-infinite" />
                  }
                >
                  <div
                    className={`ori-fixed ori-animated ori-animation-half ori-z-index-99992 ori-overflow-hidden chatbotContainer ${
                      page_details.device_data.screen_width < 768 ||
                      page_details.device_data.screen_height < 400
                        ? chatbotStyle.containerMobileClass
                        : chatbotStyle.containerWebClass
                    }  ${
                      chat_details.is_chat_open
                        ? chatbotStyle.containerInAnimationClass
                        : chatbotStyle.containerOutAnimationClass
                    }`}
                  >
                    <ChatBot
                      ref={this.chatbotRef}
                      screen_height={page_details.device_data.screen_height}
                      chat_details={chat_details}
                      actions={actions}
                      sendTextToServer={this.sendTextToServer}
                      handleMsgBtnClick={this.handleMsgBtnClick}
                      handleFileUpload={this.handleFileUpload}
                      handleOfferSelection={this.handleOfferSelection}
                      handleDataSubmit={this.handleDataSubmit}
                      handleAutoEmit={this.handleAutoEmit}
                      onEndChatSuccess={this.onEndChatSuccess}
                      handleDocxFileUpload={this.handleDocxFileUpload}
                      downloadFile={this.downloadFile}
                    />
                  </div>
                </Suspense>
              )}
              {chatbot_setting.notification_bot.visibility &&
                !chat_details.is_chat_open &&
                chat_details.unseen_messages.length > 0 && (
                  <Suspense fallback={null}>
                    <NotificationBot
                      page_details={page_details}
                      chat_details={chat_details}
                      actions={actions}
                      sendTextToServer={this.sendTextToServer}
                      handleMsgBtnClick={this.handleMsgBtnClick}
                      handleFileUpload={this.handleFileUpload}
                      handleOfferSelection={this.handleOfferSelection}
                      stack_view={chatbot_setting.notification_bot.stack_view}
                      handleDataSubmit={this.handleDataSubmit}
                      handleSocketConnection={this.handleSocketConnection}
                    />
                  </Suspense>
                )}
            </div>
          </LangContext.Provider>
        </ConfigProvider>
      )
    else return null
  }
}

const mapStateToProps = (state) => {
  return {
    chat_details: state.chat_details,
    page_details: state.page_details,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      Object.assign({}, pageActions, chatActions),
      dispatch
    ),
  }
}

AppContainer.propTypes = {
  actions: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer)
