import { INFO_CONTENT_TYPE } from "./constants"

//======= WARNING: All keys are REQUIRED for chatbot text in any language ========
//----- means keys should be common in all language text.
//----- copy englishText for any new language text and update the value only.

export const english = {
  brandName: " Vi",
  online: "online",
  offline: "offline",
  connecting: "connecting...",
  endChat: "End chat",
  poweredBy: "Powered by",
  typeYourQuery: "Type your query...",
  listening: "Listening...",
  menu: "Menu",
  clearChat: "Clear chat",
  clearChatmessage: "Are you sure you want to clear the chat?",
  privacyPolicy: "Privacy Policy",
  feedback: "Feedback",
  termsAndConditions: "Terms & Conditions",
  connectionLost: "connection lost",
  feedbackGreeting: "How happy are you with our support?",
  feedbackLowRatedStatus: "Please tell us what went wrong.",
  feedbackHighRatedStatus:
    "Please suggest how can we make your next visit awesome.",
  feedbackSuccess: "Thank you for giving us feedback",
  feedbackFailed: "Some error occured please try again later",
  downtimeStatusPrefix: "we will try to fix it before",
  downtimeStatusPostfix: ". sorry for the inconvenience.",
  sessionCloseConfirmation: "Do you want to close this session?",
  resolveChatInfo: "Agent has ended the conversation.",
  writeYourComment: "Write your comment…",
  fileSelected: "No preview found",
  confirm: "Confirm",
  cancel: "Cancel",
  ok: "Ok",
  skip: "Skip",
  Yes: "Yes",
  No: "No",
  submit: "Submit",
  success: "Success",
  failed: "Failed",
  close: "Close",
  send: "Send",
  show_more: "Show more",
  show_less: "Show less",
  overflow_showmore: "Show more",
  security_prompt:
    "This is a confidential URL. To access this URL please enter your security code.",
  today: "Today",
  yesterday: "Yesterday",
  typewriter_data: ["Click here to type your query..."],
  download_chatlog_description:
    "you can only download/email last 24 hours of chatlogs",
  download_chatlog: "Download chatlogs",
  email_chatlog: "Email chatlogs",
  download: "Download",
  choose_chatlog_type: "Choose any format to continue",
  invalid_email_text: "Please enter a valid email id",
  unknown_type_info_content:
    "is not define in info content. Click here to close this.",
  api_error: "Something went wrong",
  info_content: {
    [INFO_CONTENT_TYPE.PRIVACY_POLICY]: "", // Enter Html string here
    [INFO_CONTENT_TYPE.TERMS_AND_CONDITIONS]: "", // Enter Html string here
  },
  file_upload: "File Upload",
  no_of_pages: "pages",
  input_lock_text: "please select any option to proceed",
  reply: "Reply",
}

export const hindi = {
  brandName: "Vi",
  online: "ऑनलाइन",
  offline: "ऑफ़लाइन",
  connecting: "जोड़ रहे है...",
  endChat: "बातचीत बंद करें",
  poweredBy: "द्वारा संचालित-",
  typeYourQuery: "अपने सवाल यहाँ लिखें...",
  listening: "सुन रहे हैं...",
  menu: "सूचि",
  clearChat: "बातचीत मिटाये",
  privacyPolicy: "गोपनीयता नीति",
  feedback: "प्रतिपुष्टि",
  termsAndConditions: "नियम और शर्तें",
  connectionLost: "संपर्क टूट गया",
  feedbackGreeting: "आप हमारे समर्थन से कितने खुश हैं?",
  feedbackLowRatedStatus: "कृपया हमें बताएं कि क्या गलत हुआ।",
  feedbackHighRatedStatus:
    "कृपया सुझाव दें कि हम आपकी अगली यात्रा को कैसे शानदार बना सकते हैं।",
  feedbackSuccess: "हमें अपनी प्रतिक्रिया देने के लिए धन्यवाद",
  feedbackFailed: "कुछ त्रुटि हुई, कृपया बाद में पुनः प्रयास करें",
  downtimeStatusPrefix: "हम इसे",
  downtimeStatusPostfix:
    "से पहले ठीक करने का प्रयास करेंगे। असुविधा के लिए खेद है।",
  sessionCloseConfirmation: "क्या आप इस सत्र को बंद करना चाहते हैं?",
  resolveChatInfo: "एजेंट ने बातचीत खत्म कर दी है।",
  writeYourComment: "अपनी टिप्पणी लिखें…",
  fileSelected: "फ़ाइल का चयन कर लिया गया है",
  confirm: "पुष्टि करें",
  cancel: "रद्द करे",
  ok: "ठीक है",
  skip: "छोड़ें",
  submit: "प्रस्तुत",
  success: "सफल",
  failed: "असफल",
  close: "बंद करे",
  send: "भेजें",
  security_prompt:
    "यह एक गोपनीय URL है। इस URL तक पहुँचने के लिए कृपया अपना सुरक्षा कोड दर्ज करें।",
  typewriter_data: ["अपने सवाल टाइप करने के लिए यहां क्लिक करें..."],
  info_content: {
    [INFO_CONTENT_TYPE.PRIVACY_POLICY]: {
      // define privacy policy content here
      header: "गोपनीयता नीति",
      data: [
        {
          title: "",
          subtitle: "", // add subtitle key only if requires
          content: "",
        },
      ],
    },
    [INFO_CONTENT_TYPE.TERMS_AND_CONDITIONS]: {
      // define terms & conditions content here
      header: "नियम और शर्तें",
      data: [
        {
          title: "",
          content: "",
        },
      ],
    },
  },
}

export const arabic = {
  brandName: "اوريسيرف", //Oriserve
  online: "متصل", //online
  offline: "غير متصل على الانترنت", //offline
  connecting: "توصيل...", //connecting...
  endChat: "نهاية المحادثة", //End chat
  poweredBy: "مشغل بواسطة", //Powered by
  typeYourQuery: "اكتب استعلامك ...", //Type your query...
  listening: "الاستماع ...", //Listening...
  menu: "قائمة الطعام", //Menu
  clearChat: "دردشة واضحة", //Clear chat
  clearChatmessage: "هل أنت متأكد أنك تريد مسح الدردشة؟", //Are you sure you want to clear the chat?
  privacyPolicy: "سياسة الخصوصية", //Privacy Policy
  feedback: "استجابة", //Feedback
  termsAndConditions: "البنود و الظروف", //Terms & Conditions
  connectionLost: "فقد الاتصال", //connection lost
  feedbackGreeting: "ما مدى سعادتك بدعمنا؟", //How happy are you with our support?
  feedbackLowRatedStatus: "من فضلك قل لنا ما الخطأ الذي حدث.", //Please tell us what went wrong.
  feedbackHighRatedStatus: "يرجى اقتراح كيف يمكننا جعل زيارتك القادمة رائعة.", //Please suggest how can we make your next visit awesome.
  feedbackSuccess: "شكرا لتزويدنا بالتغذية الراجعة", //Thank you for giving us feedback
  feedbackFailed: "حدث خطأ ما يرجى المحاولة مرة أخرى في وقت لاحق", //Some error occured please try again later
  downtimeStatusPrefix: "سنحاول إصلاحه من قبل", //we will try to fix it before
  downtimeStatusPostfix: ". آسف للإزعاج.", //. sorry for the inconvenience.
  sessionCloseConfirmation: "هل تريد إغلاق هذه الجلسة؟", //Do you want to close this session?
  resolveChatInfo: "أنهى الوكيل المحادثة.", //Agent has ended the conversation.
  writeYourComment: "اكتب تعليقك ...", //Write your comment…
  fileSelected: "تم اختيار الملف", //File has been selected
  confirm: "يتأكد", //Confirm
  cancel: "يلغي", //Cancel
  ok: "موافق", //Ok
  skip: "يتخطى", //Skip
  Yes: "نعم", //Yes
  No: "رقم", //No
  submit: "يُقدِّم", //Submit
  success: "النجاح", //Success
  failed: "باءت بالفشل", //Failed
  close: "قريب", //Close
  send: "إرسال", //Send
  show_more: "إظهار المزيد", //Show more
  show_less: "تظهر أقل", //Show less
  overflow_showmore: "ظهار المزيد!", //Show more
  today: "اليوم", //Today
  yesterday: "في الامس", //Yesterday
  security_prompt:
    "هذا عنوان URL سري. للوصول إلى هذا الموقع الرجاء إدخال رمز الحماية الخاص بك.", //This is a confidential URL. To access this URL please enter your security code.
  download_chatlog_description:
    "يمكنك فقط تنزيل / إرسال مدونات الدردشة عبر البريد الإلكتروني لمدة 24 ساعة", //you can only download/email last 24 hours of chatlogs
  download_chatlog: "تنزيل سجل الدردشة", //Download chatlog
  email_chatlog: "سجل الدردشة عبر البريد الإلكتروني", //Email chatlog
  download: "تحميل", //Download
  choose_chatlog_type: "",
  invalid_email_text: "",
  unknown_type_info_content: "لم يتم تعريفه في محتوى المعلومات. رجاءا تأكد.", //is not define in info content. please check it.
  api_error: "هناك خطأ ما", //Something went wrong
  info_content: {
    [INFO_CONTENT_TYPE.PRIVACY_POLICY]: "", // Enter Html string here
    [INFO_CONTENT_TYPE.TERMS_AND_CONDITIONS]: "", // Enter Html string here
  },
  file_upload: "تحميل الملفات", //File Upload
  no_of_pages: "الصفحات", //pages
  input_lock_text: "الرجاء تحديد أي خيار يتابع", //please select any option to continue
  reply: "Reply",
}
